import moment from 'moment';
import { TRACKCB_CONVERSION_API_SYNC_TYPES } from '../../config/constants/meta/mediaPlatformApiSettingsConstants';
import { START_DATE_TYPES } from '../../config/constants/apiConstants';
import { formatBrandName } from '../brandUtils';
import{ getDate } from '../timeUtils';

export const buildTrackCBSections = (brandFetchStatusData, brands) => [
  {
    title: 'Last Sync',
    data: getSyncStatusData({
      brandFetchStatusData,
      brands,
      type: TRACKCB_CONVERSION_API_SYNC_TYPES.LAST_SYNC,
    }),
  },
  {
    title: '"Last 7 Days" Sync',
    data: getSyncStatusData({
      brandFetchStatusData,
      brands,
      type: TRACKCB_CONVERSION_API_SYNC_TYPES.SEVEN_DAYS,
    }),
  },
  {
    title: 'Historical Data Sync',
    data: getSyncStatusData({
      brandFetchStatusData,
      brands,
      type: TRACKCB_CONVERSION_API_SYNC_TYPES.CUSTOM,
    }),
  },
];

const getSyncStatusData = ({ brandFetchStatusData, brands, type }) => {
  let filteredData;

  switch (type) {
    case TRACKCB_CONVERSION_API_SYNC_TYPES.SEVEN_DAYS:
      filteredData = brandFetchStatusData.filter(item => item.seven_days_data_update);
      break;

    case TRACKCB_CONVERSION_API_SYNC_TYPES.CUSTOM:
      filteredData = brandFetchStatusData.filter(item => item.custom_period_data_update);
      break;

    default:
      filteredData = brandFetchStatusData;
      break;
  }

  return filteredData.map(item => formatSyncEntry({ item, brands, type }));
};

const formatSyncEntry = ({ item, brands, type }) => {
  const brandNameWithId = formatBrandName(item.brand_id, brands);

  switch (type) {
    case TRACKCB_CONVERSION_API_SYNC_TYPES.LAST_SYNC: {
      const time = item.last_update_time ? `Last Update: ${item.last_update_time}` : 'No updates yet';
      const hasErrors = item.errors?.[START_DATE_TYPES.LAST_RECORD_TIME] === true;
      const status = hasErrors ? 'Error' : 'Success';

      return `${brandNameWithId} (${time}, Status: ${status})`;
    }

    case TRACKCB_CONVERSION_API_SYNC_TYPES.SEVEN_DAYS: {
      const { period, zero_events_count } = item.seven_days_data_update;
      const status = zero_events_count ? 'No events found' : 'Events found and processed';
      return `${brandNameWithId} (Period: ${period}, Status: ${status})`;
    }

    case TRACKCB_CONVERSION_API_SYNC_TYPES.CUSTOM: {
      const { period, zero_events_count } = item.custom_period_data_update;
      const status = zero_events_count ? 'No events found' : 'Events found and processed';
      return `${brandNameWithId} (Period: ${period}, Status: ${status})`;
    }

    default:
      return '';
  }
};

export const initializeCustomDateRange = ({ getValues, record, dateRangeField }) => {
  const savedRange = getValues(`settings.${dateRangeField}`) || record?.settings?.[dateRangeField];

  return {
    startDate: getDate(savedRange?.start_date),
    endDate: getDate(savedRange?.end_date),
  };
};

export const updateCustomDateRange = ({ ranges, setValue, dateRangeField }) => {
  const updatedRange = {
    start_date: moment(ranges.selection.startDate).format('YYYY-MM-DD'),
    end_date: moment(ranges.selection.endDate).format('YYYY-MM-DD'),
  };

  setValue(`settings.${dateRangeField}`, updatedRange, { shouldDirty: true });

  return {
    startDate: getDate(updatedRange.start_date),
    endDate: getDate(updatedRange.end_date),
  };
};
