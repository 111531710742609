import { TrackCBSyncDescription } from './TrackCBSyncDescription';
import { TRACKCB_CONVERSION_API_HELPER_TEXT } from '../../../../../config/constants/meta/mediaPlatformApiSettingsConstants'
import { useBrands } from '../../../../../hooks/useBrands';
import { buildTrackCBSections } from '../../../../../utils/meta/mediaPlatformApiSettingsUtils';

export const TrackCBHelperText = ({ brandFetchStatusData }) => {
  const { brands } = useBrands();
  const sections = buildTrackCBSections(brandFetchStatusData, brands);

  return (
    <>
      <span>{TRACKCB_CONVERSION_API_HELPER_TEXT}</span>
      {sections.map(({ title, data }) => (
        <TrackCBSyncDescription key={title} title={title} data={data} />
      ))}
    </>
  );
};
