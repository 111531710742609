export const ACTION_SOURCE_CHOICES = [
  { id: 'email', name: 'Email' },
  { id: 'website', name: 'Website' },
  { id: 'app', name: 'App' },
  { id: 'phone_call', name: 'Phone Call' },
  { id: 'chat', name: 'Chat' },
  { id: 'physical_store', name: 'Physical Store' },
  { id: 'system_generated', name: 'System Generated' },
  { id: 'renewal', name: 'Renewal' },
  { id: 'business_messaging', name: 'Business Messaging' },
  { id: 'other', name: 'Other' },
];

export const DATA_SOURCES = {
  TUNE: 0,
  TRACKCB: 1,
  TRACKCB_CLICKS: 2,
};

export const PROCESS_STATUSES = {
  TUNE_PROCESSED: 0,
  TRACKCB_PROCESSED: 1,
  FB_CONVERSION_API_SUCCESS: 2,
  PROCESSING_ERROR: 3,
  SKIP_PROCESSING: 4,
  TRACKCB_DATA_MISSED: 5,
  SIGNATURE_ERROR: 6,
  FETCHED_REGULAR_TRACKCB_DATA: 7,
  FB_CONVERSION_API_ERROR: 8,
  FETCHED_HISTORICAL_TRACKCB_DATA: 9,
  CONVERTED_HISTORICAL_TRACKCB_DATA: 10,
  FETCHED_REGULAR_CLICK_EVENTS: 11,
  FETCHED_HISTORICAL_CLICK_EVENTS: 12,
  CONVERTED_HISTORICAL_CLICK_EVENTS: 13,
};

export const dataSourceOptions = [
  { id: DATA_SOURCES.TUNE, name: 'Tune' },
  { id: DATA_SOURCES.TRACKCB, name: 'TrackCB' },
  { id: DATA_SOURCES.TRACKCB_CLICKS, name: 'TrackCB Clicks' },
];

export const FB_EVENT_NAMES = {
  ADD_TO_CART: "AddToCart",
  INITIATE_CHECKOUT: "InitiateCheckout",
  PURCHASE: "Purchase",
  PURCHASE_CPA: "PurchaseCPA",
  PURCHASE_RETURN: "PurchaseReturn",
};

export const GOAL_NAMES = {
  ADD_TO_CART: "Add To Cart",
  CHECK_OUT: "Check Out",
  PURCHASE: "Purchase",
  PURCHASE_CPA: "Purchase CPA",
};

export const EVENT_TYPES = {
  ADD_TO_CART: "cart_add",
  CHECK_OUT: "order_checkout",
  PURCHASE: "order_processing",
};

export const TUNE_PURCHASE_CHOICES = [
  { id: FB_EVENT_NAMES.PURCHASE, name: FB_EVENT_NAMES.PURCHASE },
  { id: FB_EVENT_NAMES.PURCHASE_RETURN, name: FB_EVENT_NAMES.PURCHASE_RETURN },
  { id: FB_EVENT_NAMES.PURCHASE_CPA, name: FB_EVENT_NAMES.PURCHASE_CPA },
];

export const EVENT_TYPE_OPTIONS = [
  { id: 0, name: 'All' },
  { id: 1, name: 'Specific' },
];

export const TUNE_GOAL_NAMES = [
  { id: 0, name: GOAL_NAMES.ADD_TO_CART },
  { id: 1, name: GOAL_NAMES.CHECK_OUT },
  { id: 2, name: GOAL_NAMES.PURCHASE },
  { id: 3, name: GOAL_NAMES.PURCHASE_CPA },
];

export const TRACKCB_EVENT_TYPES = [
  { id: 0, name: EVENT_TYPES.ADD_TO_CART },
  { id: 1, name: EVENT_TYPES.CHECK_OUT },
  { id: 2, name: EVENT_TYPES.PURCHASE },
];

export const AFFILIATE_OPTIONS_TUNE = [
  { id: 0, name: 'Tune All Affiliates' },
  { id: 1, name: 'Tune Specific Affiliates' },
];

export const AFFILIATE_OPTIONS_TRACKCB = {
  NONE: 0,
  ALL: 1,
  LIST: 2,
  NO_TRANSACTION: 3,
};

export const AFFILIATE_OPTIONS_TRACKCB_LIST = [
  { id: AFFILIATE_OPTIONS_TRACKCB.NONE, name: 'All Data' },
  { id: AFFILIATE_OPTIONS_TRACKCB.ALL, name: 'Tune All Affiliates - with TID' },
  { id: AFFILIATE_OPTIONS_TRACKCB.LIST, name: 'Tune Specific Affiliates' },
  { id: AFFILIATE_OPTIONS_TRACKCB.NO_TRANSACTION, name: 'TrackCB without Affiliates - without TID' }
];

export const START_DATE_TYPES = {
  LAST_RECORD_TIME: 0,
  LAST_7_DAYS: 1,
  CURRENT: 2,
  CUSTOM: 3,
};

export const START_DATE_OPTIONS = [
  { id: START_DATE_TYPES.LAST_RECORD_TIME, name: 'Sync Live Data' },
  { id: START_DATE_TYPES.LAST_7_DAYS, name: 'Sync Last 7 days' },
  { id: START_DATE_TYPES.CURRENT, name: 'Sync Last 20 minutes' },
  { id: START_DATE_TYPES.CUSTOM, name: 'Sync Custom Data' },
];
