import { useEffect } from 'react';
import { List } from 'react-admin';
import { MediaPlatformSettingsDataGrid } from './MediaPlatformSettingsDataGrid';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { DocumentationButton } from '../../../helperComponents/DocumentationButton';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';
import { setDefaultColumns } from '../../../../utils/localStorageUtils';
import { mediaPlatformSettingsFilters } from '../../../../utils/filters/mediaPlatformSettingsFilters';

export const MediaPlatformSettingsList = (props) => {
  //Workaround because MediaPlatformApiSettings.defaultOffColumns-platform doesn't work because it's source is internal_account_id
  useEffect(() => {
    setDefaultColumns({
      preferenceKey: 'media_platform_api_settings.datagrid.columns',
      defaultColumns: ["0", "1", "2", "3", "4", "5", "7", "9", "10"]
    });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <TitleAboveTable title="Media platform API settings" />
        <DocumentationButton
          reference="media-platform-api-documentation"
          label="Media platform API settings Documentation"
        />
      </div>
      <List
        {...props}
        title=" "
        actions={<ToolBarRowStyleToggle showSelectColumnsButton showExportButton={false} />}
        pagination={<TablePagination />}
        filters={mediaPlatformSettingsFilters}
      >
        <MediaPlatformSettingsDataGrid />
      </List>
    </>
  );
};
