import {
  DatagridConfigurable,
  TextField,
  FunctionField,
  ReferenceField,
  BooleanField,
  useListContext
} from 'react-admin';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { useBrands } from '../../../../hooks/useBrands';
import { AffiliateNamesField } from '../../../helperComponents/AffiliateNamesField';
import { TotalRows } from '../../../helperComponents/TotalRows';
import { MediaPlatformApiSettings } from '../../../../helpers/constants';
import { BulkAction } from '../../../../components/helperComponents/BulkAction';
import { renderMultipleBrandsWithNames } from '../../../../utils/brandUtils';

export const MediaPlatformSettingsDataGrid = () => {
  const rowClassName = useRowStyle();
  const { total } = useListContext();
  const { brands } = useBrands();

  return (
    <>
      <TotalRows total={total} />
      <DatagridConfigurable
        rowClick="edit"
        className={`${rowClassName} datagrid-content`}
        omit={MediaPlatformApiSettings.defaultOffColumns}
        bulkActionButtons={<BulkAction showCopyButton={true} showDeleteButton={true} />}
      >
        <TextField source="id" label="ID" />
        <TextField source="internal_account_id" label="Internal Account ID" />
        <ReferenceField
          source="internal_account_id"
          reference="reference_fields/platform_account_info"
          label="Account ID"
        >
          <TextField source="account_id"/>
        </ReferenceField>
        <ReferenceField
          source="internal_account_id"
          reference="reference_fields/platform_account_info"
          label="Account Name"
        >
          <TextField source="account_name"/>
        </ReferenceField>
        <FunctionField
          label="Tune Affiliate IDs"
          render={(record) => (
            <AffiliateNamesField affiliate_ids={record.affiliate_ids} />
          )}
          source="affiliate_ids"
        />
        <TextField source="event_source_url" label="Event source URL" />
        <TextField source="domain_path" label="Domain Path" />
        <TextField source="fb_pixel_id" label="Facebook pixel ID" />
        <FunctionField
          source="fb_api_key"
          label="Facebook API key"
          render={record => (
            <div style={{ width: '280px', wordWrap: 'break-word' }}>
              {record.fb_api_key}
            </div>
          )}
        />
        <TextField source="payout_percentage" label="Tune Payout Percentage" />
        <TextField source="payout_percentage_trackcb" label="TrackCB Payout Percentage" />
        <ReferenceField
          source="internal_account_id"
          reference="reference_fields/platform_account_info"
          label="Platform"
          link={false}
        >
          <TextField source="platform" />
        </ReferenceField>
        <TextField source="action_source" label="Action Source" />
        <TextField source="tune_purchase" label="Tune Purchase" />
        <TextField source="tune_purchase_cpa" label="Tune Purchase CPA" />
        <FunctionField
          label="TrackCB Affiliate IDs"
          render={(record) => (
            <AffiliateNamesField affiliate_ids={record.affiliate_ids_trackcb} />
          )}
          source="affiliate_ids"
        />
        <FunctionField
          label="Brands"
          source="brand_ids"
          render={(record) => renderMultipleBrandsWithNames(record, brands)}
        />
        <BooleanField source="settings.enable_pulling_data_tune" label="Enable Sending Tune Data" />
        <BooleanField source="settings.enable_pulling_data_trackcb" label="Enable Sending TrackCB Data" />
        <BooleanField source="settings.enable_pulling_data_clicks" label="Enable Sending Clicks Data" />
      </DatagridConfigurable>
    </>
  );
};
